export const OVERVIEW_STEP_PATH = 'overview'
export const DATES_AND_STATES_STEP_PATH = 'dates-and-states'
export const PERILS_STEP_PATH = 'perils'
export const REVIEW_STEP_PATH = 'review'

export const stepOrder = [OVERVIEW_STEP_PATH, DATES_AND_STATES_STEP_PATH, PERILS_STEP_PATH, REVIEW_STEP_PATH]

export const validStepNameParam = (param) => {
  return stepOrder.includes(param)
}
