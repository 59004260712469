export default defineNuxtPlugin(() => {
  const { public: config } = useRuntimeConfig()

  const chat = {
    setupChat: (zendeskChatJWT) => {
      const script = document.createElement('script')
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.zendeskWidgetKey}`
      script.id = 'ze-snippet'

      script.onload = () => {
        if (window.zE) {
          zE('messenger', 'hide')

          zE('messenger:on', 'open', () => {
            zE('messenger', 'show')
          })

          zE('messenger:on', 'close', () => {
            zE('messenger', 'hide')
          })

          zE('messenger', 'loginUser', (callback) => {
            callback(zendeskChatJWT)
          })
        }
      }

      document.head.appendChild(script)
    },
    openChat: () => {
      zE('messenger', 'open')
    },
    logoutChat: () => {
      zE('messenger', 'logoutUser')
    },
  }

  return {
    provide: {
      chat,
    },
  }
})
